import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { SessionService } from './session.service';

@Injectable()
export class LoginAuthGuard implements CanActivate {
  constructor(private sessionService: SessionService, private router: Router) {}

  public canActivate (): Promise<boolean> {
    return new Promise(resolve => {
      this.sessionService.getCurrentUser().then(user => {
        this.router.navigate(['app']);       
        resolve(false);
      }, error => {
        if ( sessionStorage.getItem('firsttimetoken') ) {
          this.router.navigate(['firstTime']);
          resolve(false);
        } else if ( sessionStorage.getItem('resettoken') ) {
            this.router.navigate(['resetPassword']);
            resolve(false);
        } else {
          resolve(true);
        }
      });
    });
  }
}

@Injectable()
export class AppAuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private sessionService: SessionService   
  ) {}

  public canActivate (snapshot:ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {    
    return new Promise(resolve => {
      this.sessionService.getCurrentUser().then(user => {    
        if (this.authService.hasPermission(snapshot.routeConfig.path))
          resolve(true);
        else 
          this.router.navigateByUrl('/app/notauthorized');
          resolve(false);

      }, error => {
         let redirectUrl = '';
        // Do not set holding state as redirectUrl
        if (state.url.indexOf('holding') === -1) {
          this.authService.redirectUrl = state.url;
          redirectUrl = state.url;
        }
        this.authService.logOut();
        sessionStorage.setItem('redicectUri', redirectUrl);
        resolve(false);
      });
    });
  }
}
